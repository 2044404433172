import smoothscroll from 'smoothscroll-polyfill';
import fancybox from '@fancyapps/fancybox';
import MarkerClusterer from '@google/markerclusterer';

// kick off the polyfill!
smoothscroll.polyfill();
let lastMenuOpened;
let lastNavigationText;

/* eslint-disable one-var */
$(function() {
/* Base */
/* ----------------------------------------- */
  let didScroll;
  let lastScrollTop = 0;
  let delta = 5;
  let classEl = $('body');
  let navbar = $('header.header');
  let navbarHeight = navbar.outerHeight();

  $(window).scroll(function(event) {
      didScroll = true;
  });

  setInterval(function() {
      if (didScroll) {
          hasScrolled();
          didScroll = false;
      }
  }, 250);

  function hasScrolled() {
      let st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight) {
          // Scroll Down
          classEl.removeClass('nav--down').addClass('nav--up');
      } else {
          // Scroll Up
          if (st + $(window).height() < $(document).height()) {
              classEl.removeClass('nav--up').addClass('nav--down');
          }
      }

      lastScrollTop = st;
  }

/* ----------------------------------------- Base */

/* Helpers */
/* ----------------------------------------- */
  function preload(arrayOfImages) {
    $(arrayOfImages).each(function() {
        $('<img/>')[0].src = this;
    });
  }
  function preLoadSliderImages(dataset) {
    let srcs = [];
    if (dataset == undefined) return true;
    if (dataset.bgDesktop != undefined) srcs.push(dataset.bgDesktop);
    if (dataset.bgMobile != undefined) srcs.push(dataset.bgMobile);
    if (srcs.length > 0) {
      preload(srcs);
    }
  }
/* ----------------------------------------- Helpers */


/* Menu Mobile Full */
/* ----------------------------------------- */
  // let tlMenu = new TimelineMax({paused: false});
  let tlMenu = new TimelineMax({paused: true});
  let header = '.header--mobilefull';
  let navContainer = '.header--mobilefull .navbar--container';
  let navNavigation = '.header--mobilefull .navbar--navigation';
  let navMenu = '.header--mobilefull .navbar--menu';
  let navMenuItems = '.header--mobilefull .navbar--menu > li';
  // Reset nos items
  tlMenu.set(header, {height: '100vh'});
  tlMenu.set(navContainer, {autoAlpha: 1});
  tlMenu.set(navNavigation, {autoAlpha: 0, y: -100});
  tlMenu.set(navMenu, {autoAlpha: 0, overflow: 'scroll'});
  tlMenu.set(navMenuItems, {autoAlpha: 0, y: 30});
  // Abre o container na diagonal
  tlMenu.to(navContainer, 1, {height: '100%', width: '100%', borderBottomRightRadius: '0px', ease: Expo.easeInOut}, 'Inicio');
  // Bloqueia o body para não ter scroll
  tlMenu.set('body', {overflowY: 'hidden'});
  // tlMenu.set('body', {height: '100vh', overflowY: 'hidden', marginTop: 0});
  // Exibe os items do menu
  tlMenu.to(navNavigation, .3, {autoAlpha: 1, y: 0});
  tlMenu.set(navMenu, {autoAlpha: 1});
  tlMenu.staggerTo(navMenuItems, .35, {autoAlpha: 1, y: 0, clearProps: 'all'}, .15);
  tlMenu.set(navMenuItems, {clearProps: 'all'});

  // Transition between submenu on mobile
  let navbarNavigation = $('.navbar--navigation--current');
  if ($(window).width() < 992 ) {
    // Active class to show menu
    $('.js-toggle-menu').click(function(e) {
      e.preventDefault();
      tlMenu.invalidate().play();
    });

    // Add class to submenu when clicked in parent
    $('.navbar--menu .menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      let newText = $(this).text();
      // console.log('Atualizando lastNavigationText para ', navbarNavigation.text());
      lastNavigationText = navbarNavigation.text();
      // console.log('Atualizando navbarNavigation para ', newText);
      navbarNavigation.text(newText);
      openSubMenu($(this));
    });

    // Remove submenu class when clicked to back
    $('.navbar--navigation--back').click(function(e) {
      if (lastNavigationText != '') {
        navbarNavigation.text(lastNavigationText);
      }
      closeSubMenu();
    });

    $('.navbar--navigation--close').click(function(e) {
      let tl = new TimelineMax();
      // Verifica se existem submenus abertos
      let items = lastMenuOpened ? lastMenuOpened.find(' > li') : navMenuItems;

      // Limpa o navigation text
      navbarNavigation.text('');
      // SlideDown os itens do menu
      tl.to(navNavigation, .35, {autoAlpha: 0, y: -100});
      tl.staggerTo(items, .35, {autoAlpha: 0, y: 30}, .05, 0);
      // Fecha o container na diagonal
      tl.set(navMenu, {overflow: 'hidden'});
      tl.set(navContainer, {borderBottomRightRadius: '40px'});
      tl.to(navContainer, .7, {height: 0, width: 0});
      // Reseta os atributos
      tl.set($(navContainer).find('.sub-menu'), {clearProps: 'all'});
      tl.set(header, {clearProps: 'all'});
      tl.set('body', {clearProps: 'all'});
      // Desbloqueia o body
      // tl.set('body', {maxHeight: '100%', overflowY: 'visible'});
    });
  }

  function updateLastMenuOpened(el) {
    // Adiciona a classe do elemento anterior
    $(el).parent('li').addClass('hide-subs');
    // Remove a classe do elemento anterior
    $(lastMenuOpened).parent('li').removeClass('hide-subs');
    // Atualiza o ultimo menu aberto
    lastMenuOpened = el;
    // Update the Navigation Text
    if ($(el).hasClass('navbar--menu')) {
      navbarNavigation.text('');
      $('.navbar--navigation--back').removeClass('visible');
    } else {
      $('.navbar--navigation--back').addClass('visible');
    }
  }

  function openSubMenu(el) {
    let tl = new TimelineMax();
    let ul = el.next();
    let items = ul.find('> li');
    let parentList = $(el).parent();
    let parentListSiblings = parentList.siblings();
    // Store the menu to back if necessary
    updateLastMenuOpened(ul);
    // Esconde os items da lista
    tl.set(items, {autoAlpha: 0, y: 30});
    // Esconde os LIs do parent
    tl.set(parentListSiblings, {display: 'none'});
    // Exibe o submenu
    tl.to(ul, .3, {zIndex: 2, autoAlpha: 1});
    // Exibe os items do menu
    tl.staggerTo(items, .35, {autoAlpha: 1, y: 0, clearProps: 'all'}, .15);
  }

  function closeSubMenu() {
    // Update the Navigation Text
    let tl = new TimelineMax();
    let parentMenu = $(lastMenuOpened).parent().closest('ul');
    let parentMenuItems = parentMenu.find(' > li');
    // Esconde os LIs do parent
    tl.set(parentMenuItems, {display: 'block', autoAlpha: 0, y: 30});
    // Esconde o menu atual
    tl.set(lastMenuOpened, {zIndex: -1, autoAlpha: 0});
    // Anima os LIs do parent
    tl.staggerTo(parentMenuItems, .35, {autoAlpha: 1, y: 0, clearProps: 'all'}, .15);
    // Atualiza o ultimo menu aberto
    updateLastMenuOpened(parentMenu);
  }

  // Prevene o link da home se clicar na arrow
  $('body.home .menu-item-home a').on('click', function(e) {
    let parent = $(this).parent('li');
    if (!parent.hasClass('ontop')) {
      e.preventDefault();
      let distance = $('header.header').offset().top;
      window.scroll({top: distance, left: 0, behavior: 'smooth'});
    }
  });
/* ----------------------------------------- Menu Mobile Full */

/* Maps */
/* ----------------------------------------- */
  $.each($('.g-maps'), function(i, el) {
    // Inicia o maps
    let map = new google.maps.Map(el, {
      // zoom: 10,
      scrollWheel: false,
      disableDefaultUI: false,
      styles: JSON.parse(siteVars.styleMaps),
    });
    let infowindow = new google.maps.InfoWindow();
    let bounds = new google.maps.LatLngBounds();
    // Pega as localizações
    let locations = JSON.parse(el.dataset.locations);

    let markers = locations.map(function(el) {
      // Create the marker
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(el.geo.lat, el.geo.lng),
        label: el.title,
        icon: siteVars.pinMaps,
      });
      // Add mark to bounds
      bounds.extend(marker.position);
      // Add listining to click in the event
      google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
              infowindow.setContent('<div class="infomaps">' + $('#infomaps-' + el.postID).wrap().html() + '</div>');
              infowindow.open(map, marker);
            };
          })(marker, i));

      return marker;
    });
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer(map, markers, {imagePath: siteVars.iconMaps});

    // Centraliza o mapa pelos bounds
    map.fitBounds(bounds);

    // Altera o zoom caso estiver apenas um item
    if (locations.length === 1) {
      let listener = google.maps.event.addListener(map, 'idle', function() {
        // console.log('locationssss 3');
        map.setZoom(3);
        google.maps.event.removeListener(listener);
      });
    }
  });
/* ----------------------------------------- Maps */

/* Search */
/* ----------------------------------------- */
  const searchWrapper = $('#search-wrapper-modal');
  let tlSearch = new TimelineMax({paused: true});

  tlSearch.set(searchWrapper, {autoAlpha: 0, zIndex: -5}, 'Reset');
  tlSearch.set(searchWrapper, {zIndex: 100}, 'Start');
  tlSearch.to(searchWrapper, .3, {autoAlpha: 1, onComplete: function() {
    $('#s').focus();
  }});


  // Action on search click
  $('.js-toggle-search').click((e) => {
    tlSearch.invalidate().play();
  });

  $('.js-close-search').click((e) => {
    // console.log('teste');
    tlSearch.reverse(0);
  });

  // if ($('body').hasClass('search-no-results')) {
  //   searchWrapper.clone().appendTo('.section--pageheader');
  // }
/* ----------------------------------------- Search */

  // Hide Elements
  $('.shownext').click(function(event) {
    $(this).parent().next().remove();
    $(this).remove();
  });

/* Slides */
/* ----------------------------------------- */
  // HOME
  $('#featured--slider')
  .on('init', function(event, slick) {
    updateFeaturedBg(slick.$slides[Object.keys(slick.$slides)[0]].dataset);
    preLoadSliderImages(slick.$slides[Object.keys(slick.$slides)[1]].dataset);
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    updateFeaturedBg(slick.$slides[Object.keys(slick.$slides)[nextSlide]].dataset);
  })
  .on('afterChange', function(event, slick, currentSlide) {
    preLoadSliderImages(slick.$slides[Object.keys(slick.$slides)[currentSlide+1]].dataset);
  })
  .slick({
    // autoplay: true,
    // autoplaySpeed: 3000,
    responsive: [
      {breakpoint: 9999, settings: {arrows: true, infinite: true}},
      {breakpoint: 768, settings: {slidesToShow: 1, slidesToScroll: 1, dots: true, arrows: false}},
    ],
  });

  $('.carousel--gallery')
  .on('init', function(event, slick) {
    // Atualiza a posição das arrows conforme a imagem
    updateSlickGalleryArray(slick.$slider);
  }).on('afterChange', function(slick, currentSlide, nextSlide) {
    // Atualiza a posição das arrows conforme a imagem
    updateSlickGalleryArray($(currentSlide.$slider));
  })
  .slick({
    slidesToShow: 1,
    variableWidth: true,
    centerMode: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  });

  function updateSlickGalleryArray(el) {
    // Captura o tamanho da imagem
    let imgHeight = el.find('.slick-current img').height();
    // Atualiza a posição top das arrows
    el.find('.slick-arrow').css('top', imgHeight/2);
  }

  $( window ).on( 'load resize', function() {
    // Corrige bug de setas desalinhas nas galerias
    let gallerys = $('.carousel--gallery');
    if (gallerys.length > 0) {
      $.each(gallerys, function(index, el) {
        updateSlickGalleryArray($(el));
      });
    }
  });


  $('.carousel--layout01').slick({
    responsive: [
      {breakpoint: 9999, settings: {slidesToShow: 6}},
      {breakpoint: 2560, settings: {slidesToShow: 5}},
      {breakpoint: 1920, settings: {slidesToShow: 4}},
      {breakpoint: 1600, settings: {slidesToShow: 3}},
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          slidesToShow: 2,
          arrows: true,
          variableWidth: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: '0px',
          arrows: false,
        },
      },
    ],
  }).on('init', function(event, slick) {
    lazyLoadInstance.update();
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    lazyLoadInstance.update();
  });

  $('.carousel--layout02').slick({
    infinite: true,
    slidesToScroll: 4,
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1170,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: '0px',
          arrows: false,
        },
      },
    ],
  }).on('init', function(event, slick) {
    lazyLoadInstance.update();
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    lazyLoadInstance.update();
  });


  $('.carousel--layout03, .carousel--layout06, .carousel--layout04').not('.count--1').slick({
    responsive: [
      {breakpoint: 9999, settings: {slidesToShow: 6}},
      {breakpoint: 2560, settings: {slidesToShow: 5}},
      {breakpoint: 1920, settings: {slidesToShow: 4}},
      {breakpoint: 1600, settings: {slidesToShow: 3}},
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1,
          variableWidth: true,
          arrows: false,
        },
      },
    ],
  }).on('init', function(event, slick) {
    lazyLoadInstance.update();
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    lazyLoadInstance.update();
  });

  $('.carousel--layout05').slick({
    infinite: true,
    slidesToScroll: 4,
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1170,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: '0px',
          arrows: false,
          infinite: false,
        },
      },
    ],
  }).on('init', function(event, slick) {
    lazyLoadInstance.update();
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    lazyLoadInstance.update();
  });

  // $('#section--depoimentos .carousel').slick({
  //   responsive: [
  //     {
  //       breakpoint: 9999,
  //       settings: 'unslick',
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //         arrows: false,
  //         touchMove: false,
  //       },
  //     },
  //   ],
  // });


  $('body.single-post .gallery').slick({
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 730,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: '0px',
          arrows: false,
        },
      },
    ],
  }).on('init', function(event, slick) {
    lazyLoadInstance.update();
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    lazyLoadInstance.update();
  });

  // Atualiza o background do featured
// Elemento foi separado do slider para funcionar o attachment fixed
function updateFeaturedBg(data) {
  // let bgType = $(window).width() < 768 ? 'bgMobile' : 'bgDesktop';
  // let bg = data[bgType];
  let bg = data['bgDesktop'];
  if (bg.length) {
    $('#featured--slider').css('background-image', 'url('+bg+')');
  }
}

/* ----------------------------------------- Slides */

/* FacetWP */
/* ----------------------------------------- */
  $('.loop--actions--clear span').on('click', function(e) {
    $('body').attr('data-order', 'clear');
  });

  $('.loop--actions--sort span[data-order]').on('click', function(e) {
    e.preventDefault();
    let val = $(this).data('order');
    // Define o sort clicado
    FWP.extras.sort = val;
    // Atualiza o facet
    FWP.refresh();
    // Marca o body para as mudanças das classes
    $('body').attr('data-order', val);
    // console.log(val);
  });

  $(document).on('facetwp-loaded', function() {
    // Set up facet options
    if (typeof FWP !== 'undefined') {
      FWP.auto_refresh = false;
      FWP.loading_handler = function() { };
    }
    // Anima a primeira entrada dos filtros
    if (!$('.loop--filters').hasClass('active')) {
      $('.loop--filters').addClass('active');
      let tl = new TimelineMax();
      tl.staggerFrom(
        '.loop--filters .facetwp-facet', .5,
        {autoAlpha: 0, y: 70, delay: 1},
      .3);
      tl.set('.loop--filters', {className: '+=active--ready'});
    }

    // Update lazyLoad
    if (typeof lazyLoadInstance !== 'undefined') {
      lazyLoadInstance.update();
    }
  });

  function toggleFilter(el) {
    $(el).toggleClass('expanded');
    $('body').toggleClass('selecting');
  }

  $('.loop--filters').on('click', '.facetwp-facet', function(e) {
    let el = $(this);
    // Adiciona a class ao elemento quando clickado
    if (!el.hasClass('expanded')) {
      toggleFilter(el);
    } else {
      if ($(e.target).hasClass('filter--close')) {
        toggleFilter(el);
      }
    }
  });

  // Close mask if click on mask
  $(window).on('click', function(e) {
    if ($('body').hasClass('selecting') && e.target.tagName == 'BODY') {
      toggleFilter($('.facetwp-facet.expanded'));
    }
  });

  $(document)
  .on('facetwp-refresh', function() {
    // console.log('On facetwp-refresh');
    // Esconde os valores selecionados
    // $(this).find('.filter--selected').removeClass('showing');
    TweenMax.to('.facetwp-template', 1, {opacity: 0});
  })
  .on('facetwp-loaded', function() {
    // console.log('On facetwp-loaded');
    TweenMax.to('.facetwp-template', 1, {opacity: 1});
    // Adiciona a label "dias" ao slider range
    let slider = $('.facetwp-type-slider');
    if (slider.length > 0) {
      let label = slider.find('span.facetwp-slider-label');
      let caption = label.next();
      if (!caption.hasClass('facetwp-slider-caption')) {
        $('<span class="facetwp-slider-caption">dias</span>').insertAfter(label);
      }
    }
    // Atualiza os valores no slider--selected text
    updateFacets();

    // Esconde os facets que não existem opções
    $.each(FWP.settings.num_choices, function(key, val) {
        let $parent = $('.facetwp-facet-' + key);
        console.log(key, val);
        console.log($parent);
        (0 === val) ? $parent.hide() : $parent.show();
    });
    // $('.facetwp-template').animate({opacity: 1}, 1000);
  });

  $('[data-layout]').on('click', function() {
    let val = $(this).data('layout');
    toggleLayout(val);
  });

  function toggleLayout(val) {
    $('body').removeClass(function(index, className) {
        return (className.match(/(^|\s)layout--\S+/g) || []).join(' ');
    });
    $('body').addClass(`layout--${val}`);
  }

  function updateFacets() {
    $('.facetwp-facet').each(function() {
      // Atualiza o facet apenas se estiver dentro do .loop--filters
      if ($(this).parent('.loop--filters').length == 0) return true;

      let $facet = $(this);
      let facetName = $facet.attr('data-name');
      let facetValues = []; // Reinitialize
      let spacer = ', ';

      // Se a faceta for checkbox
      if (FWP.facet_type[facetName] == 'checkboxes') {
        $facet.find('.checked').map((i, el) => facetValues.push(el.firstChild.data));
      } else {
        facetValues = FWP.facets[facetName].filter((el) => el != '');
      }

      // Se existiremo valores selecionados
      if (facetValues.length > 0) {
        // Encontra o item a ser atualizado
        let filterSelected = $facet.find('.filter--selected');
        // Remove o ultimo espado das strings dos valores selecionados
        facetValues = facetValues.map((el) => el.replace(/\s+$/, ''));
        // Se o facet for de idade, adiciona a palavra ano/anos
        if (facetName.includes('idade')) {
          facetValues = facetValues.map((el) => el == 1 ? el + ' ano' : el + ' anos');
        }
        // Se o facet for relacionado a dias, remove os zeros
        if (facetName.includes('dias')) {
          facetValues = facetValues.map((el) => parseInt(el));
          spacer = ' - ';
        }

        filterSelected.attr('data-text', facetValues.join(spacer));
        filterSelected.addClass('showing');
      }
    });
  }

  if ($(window).width() < 768 ) {
    toggleLayout('lista');
  }
/* ----------------------------------------- FacetWP */

/* ScrollMagic */
/* ----------------------------------------- */
  const controller = new ScrollMagic.Controller();

    // Fixa o menu na home após scroll
    let fixaMenuHome = new ScrollMagic.Scene({
      triggerElement: 'body.home.desktop header.header',
      triggerHook: 'onLeave',
    })
      .setPin('body.home.desktop header.header')
      .addTo(controller)
      // .addIndicators()
      .on('start', function(event) {
        $('.menu-item-home').toggleClass('ontop');
      });


    // Esconde o Slider Conforme a Rolagem
    let tween = TweenMax.to('#featured--slider', 0.5, {autoAlpha: 0});
    let updateFeaturedOnScroll = new ScrollMagic.Scene({
      triggerElement: '#featured--slider',
      triggerHook: 0,
      duration: '80%',
    })
      .setTween(tween)
      // .addIndicators()
      .addTo(controller);

    // Adiciona slideUp Fade a todas as Section
    $.each($('.section'), function(index, secao) {
      // Remove a animação das page--header
      if ($(secao).hasClass('section--pageheader')) return true;
      // Busca todos os elementos filhos do container
      let items = $(secao).find('.container > *');
      // Para cada filho, adiciona um Tween
      if (items.length > 0) {
        items.map((index, item) => {
          // console.log(item);
          new ScrollMagic.Scene({triggerElement: item, triggerHook: 0.9})
          .setTween(TweenMax.from(item, .6, {y: 50, autoAlpha: 0}))
          .addTo(controller);
        });
      }
    });

    // Adiciona slideUp Fade a todas os passos na consultoria
    $.each($('.passos--item'), function(index, secao) {
      // Busca todos os elementos filhos do container
      let items = $(secao).find('.container > *');
      // Para cada filho, adiciona um Tween
      if (items.length > 0) {
        items.map((index, item) => {
          // console.log(item);
          new ScrollMagic.Scene({triggerElement: item, triggerHook: 0.9})
          .setTween(TweenMax.from(item, .6, {y: 50, autoAlpha: 0}))
          .addTo(controller);
        });
      }
    });

  /* ----------------------------------------- ScrollMagic */
});

/* UI */
/* ----------------------------------------- */

  $(document).on('click', '.input--qtd .input--qtd-minus', function(e) {
    let el = $(this).siblings('input');
    let oldVal = el.val() ? el.val() : 0;
    let newValue = parseFloat(oldVal) - 1;
    $(el).val(newValue === -1 ? 0 : newValue);
    $(el).change();
  });

  $(document).on('click', '.input--qtd .input--qtd-plus', function(e) {
    let el = $(this).siblings('input');
    let oldVal = el.val() ? el.val() : 0;
    $(el).val(parseFloat(oldVal) + 1);
    $(el).change();
  });

/* ----------------------------------------- UI */

/* ChoicesJS */
/* ----------------------------------------- */

  if ($('.wpcf7-select').length) {
    $.each($('.wpcf7-select'), function(index, value) {
      window['select' + value.name] = new Choices(value, {
        placeholder: true,
        placeholderValue: 'Selecione',
        itemSelectText: 'Clique para selecionar',
      });
    });
  }

  const selectEstado = $('select[name="estado"]');
  const selectCidades = $('select[name="cidade"]');
  let cidadesPorEstado;

  if (selectEstado.length) {
    selectcidade.disable();
    // Load nas cidades
    $.getJSON(siteVars.cidades, function(data) {
      cidadesPorEstado = data;
    });

    selectEstado.on('change', function(e) {
      let estado = e.target.value;

      let options = '<option placeholder>Selecione uma cidade</option>';
      $.each(cidadesPorEstado, function(key, val) {
        if (val.nome == estado) {
          $.each(val.cidades, function(keyCity, valCity) {
            options += '<option value="' + valCity + '">' + valCity + '</option>';
          });
        }
      });

      if (estado == 'UF') {
        selectcidade.clearStore();
        selectcidade.disable();
      } else {
        selectcidade.enable();
        selectcidade.destroy();
        selectCidades.html(options);
        selectcidade = new Choices('select[name="cidade"]', {
          placeholder: true,
        });
      }
    });
  }

  const myChoices = $('select.choicesjs');
  if (myChoices.length) {
    for (let i = 0; i < myChoices.length; i++) {
      const el = myChoices[i];
      let selectText = $(el).hasClass('list') ? 'Clique para visualizar' : 'Clique para selecionar';
      let listItems = new Choices(el, {
        // placeholder: true,
        // placeholderValue: 'Selecione',
        itemSelectText: selectText,
      });
      if ($(el).hasClass('list')) {
        el.addEventListener('choice', function(e) {
          window.location = e.detail.choice.value;
        }, false);

        el.addEventListener('showDropdown', function() {
          $(el).closest('.section').addClass('section--active');
        }, false);

        el.addEventListener('hideDropdown', function() {
          $(el).closest('.section').removeClass('section--active');
        }, false);
      }
    }
  }

  // Alteração de preços entre as moedas
  let el = document.getElementById('priceselector');
  if (el) {
    let priceselector = new Choices(el, {searchEnabled: false});
    // Mostra apenas os preços dos elementos clicados
    el.addEventListener('choice', function(e) {
      updateTablePrice(e.detail.choice.value);
    }, false);
    // Atualiza os primeiros prices
    updateTablePrice(el.value);
    // Esconde/Mostra os preços na moeda definida
  }
  function updateTablePrice(currencie) {
    // Esconde todos os valores
    new TweenMax($('.table-valor > span'), .3, {autoAlpha: 0, display: 'none', onComplete: function() {
      // Exibe apenas a moeda
      new TweenMax($('.table-valor > span.' + currencie), .3, {autoAlpha: 1, display: 'block'});
    }});
  }
/* ----------------------------------------- ChoicesJS */

/* Masks */
/* ----------------------------------------- */
  // Mascara de DDD e 9 dígitos para telefones
  let SPMaskBehavior = function(val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }, spOptions = {onKeyPress: function(val, e, field, options) {
    field.mask(SPMaskBehavior.apply({}, ...e), options);
  }};
  $('.mask-phone, input[type="tel"], .wpcf7-tel').mask(SPMaskBehavior, spOptions);
  $('.money').mask('#.###', {reverse: true});
  // $('.placeholder').mask('00/00/0000', {placeholder: '__/__/____'});
  $('.mask-date').mask('00/00/0000', {placeholder: '00/00/0000', clearIfNotMatch: true});
  $('.wpcf7-email').on('change keypress', function(e) {
    e.target.value = e.target.value.toLowerCase();
  });
/* ----------------------------------------- Masks */

/* Caleran */
/* ----------------------------------------- */
  $('.data-ida-volta input').caleran({
    startEmpty: true,
    showFooter: false,
    showOn: 'bottom',
    autoAlign: true,
    autoCloseOnSelect: true,
  });
/* ----------------------------------------- Caleran */

/* Custom Inputs */
/* ----------------------------------------- */
  $.each($('.custom-number'), function(index, el) {
    let parent = $(el).parent();
    let minus = parent.find('.input--qtd-minus');
    $(el).insertAfter(minus);
  });
  // Cria os 2 campos baseados no input padrão de 02 crianças
  updateNascimentos(1);
  $('.qtd-criancas input').on('change', function(e) {
    let val = $(this).val();
    updateNascimentos(val);
  });

  // Atualiza a string de idades ao enviar o contact form
  $('.wpcf7-submit').on('click', function(e) {
      let form = $(this).parent('form');
      let formNascimentos = $('#input-nascimentos');
      const labelFormNascimentos = $('#label-nascimentos');

      // Se existir o formulário de crianças
      if (formNascimentos.length) {
        let idades = $('#input-nascimentos .idade-wrapper input').serializeArray();
        let stringIdades = idades.map(function(el) {
          return `${el.name}: ${el.value}`;
         }).join(', ');

         // Atualiza o form de string de idades
         $('#val-idade-criancas').val(stringIdades).val();

         // Verifica se os forms possuem valor;
         let validate = true;
         const alertTxt = '<span class="my-alert"> - Preencha todos os campos</span>';
         // Check idades
         $.each($('#input-nascimentos .idade-wrapper input'), function(index, el) {
            let input = $(el);
            if (!validate) return false;
            if (!input.val()) validate = false;
         });

         // Check Sexos
         const sexos = $('#input-nascimentos .sexo input');
         let qtdChilds = sexos.length/2;
         if (sexos.serializeArray().length != qtdChilds) validate = false;

         // Se tiver alguma data de nascimento não preenchida, cancela o envio.
         if (!validate) {
          if (!labelFormNascimentos.find('.my-alert').length) labelFormNascimentos.append(alertTxt);
          document.querySelector('form.wpcf7-form').scrollIntoView({behavior: 'smooth'});
          e.preventDefault();
         } else {
           labelFormNascimentos.find('.my-alert').remove();
         }
      }
  });

  // Scroll checkboxes
  $(document).on('click', '.checkbox--up', function(e) {
    e.preventDefault();
    $(this).next().get(0).scrollBy({top: -48, left: 0, behavior: 'smooth'});
  });

  $(document).on('click', '.checkbox--down', function(e) {
    e.preventDefault();
    $(this).prev().get(0).scrollBy({top: 48, left: 0, behavior: 'smooth'});
  });

  // Cria/Remove os inputs relacionados as crianças
  function updateNascimentos(qtd) {
    let box = $('#input-nascimentos');
    let currentInputs = box.find('.criancas').length;
    // Verifica quantos inputs temos atualmente
      // Se o valor for maior a quantidade de bebes
      if (qtd > currentInputs) {
        addInputNascimento(qtd - currentInputs, currentInputs);
      // Se o valor for menor
      } else if (qtd < currentInputs ) {
        removeInputNascimento(currentInputs - qtd, currentInputs);
      }
  }

  function addInputNascimento(qtdToAdd, current) {
    for (let qtd = 0; qtd < qtdToAdd; qtd++) {
      let label = `Criança ${ current + qtd + 1 }`;
      // <span class="idade">
      //   <label>Idade na data da viagem</label>
      //   <input class="mask-date" required name="crianca-${current + qtd + 1 }" type="text" />
      // </span>
      $('#input-nascimentos').append(`
        <div class="criancas crianca-${ current + qtd + 1 }">
          <p class="text-primary">${label}</p>
          <span class="idade">
            <label>Idade na data da viagem</label>
            <div class="idade-wrapper">
              <input required placeholder="00" min="0" step="1" name="crianca-idade-${current + qtd + 1 }" type="number" />
            </div>
          </span>          
          <span class="sexo">
            <label>Sexo</label>
            <span class="wpcf7-form-control-wrap">
              <span class="wpcf7-form-control wpcf7-radio">
                <span class="wpcf7-list-item first">
                  <label>
                    <input type="radio" name="crianca-sexo-${current + qtd + 1 }" value="Masculino">
                    <span class="wpcf7-list-item-label">Masculino</span>
                  </label>
                </span>
                <span class="wpcf7-list-item last">
                  <label>
                    <input type="radio" name="crianca-sexo-${current + qtd + 1 }" value="Feminino">
                    <span class="wpcf7-list-item-label">Feminino</span>
                  </label>
                </span>
              </span>
            </span>
          </span>          
        </div>
      `);
    }
    // $('#input-nascimentos .mask-date').mask('00/00/0000', {placeholder: '00/00/0000', clearIfNotMatch: true});
  }
  function removeInputNascimento(qtdToRemove, current) {
    for (let qtd = 0; qtd < qtdToRemove; qtd++) {
      $('#input-nascimentos .criancas:last-of-type').remove();
    }
  }
/* ----------------------------------------- Custom Inputs */

/* Centraliza Logo no Menu */
/* ----------------------------------------- */
  function centerLogo() {
    if ($(window).width() <= 1024 ) return false;
    let menuItemLogo = $('header.header .menu-item-home');
    let menuItemActions = $('header.header .navbar--menu li.actions');
    let totalWidth = $(window).width();

    let tl = new TimelineMax({onComplete: function() {
      // Esconde/Exibe o menu de actions
      let larguraDisponivel = totalWidth - (menuItemActions.prev().position().left + menuItemActions.prev().width());
      // console.log('larguraDisponivel', larguraDisponivel);
      if (larguraDisponivel > menuItemActions.width()) {
        menuItemActions.addClass('visible');
      } else {
        menuItemActions.removeClass('visible');
      }
    }});

    // Calcula a posição que o item deveria estar
    let posicaoDesejada = (totalWidth - menuItemLogo.outerWidth()) / 2;
    let posicaoAtual = menuItemLogo.offset().left;
    let aMover = posicaoDesejada - posicaoAtual;

    // console.log('totalWidth', totalWidth);
    // console.log('menuItemLogo.outerWidth()', menuItemLogo.outerWidth());
    // console.log('posicaoAtual', posicaoAtual);
    // console.log('aMover', aMover);

    tl.to('header.header .navbar--menu > li:first-of-type', .5, {marginLeft: aMover * 2});
    tl.to('header.header', .5, {autoAlpha: 1});
    tl.from('header.header', .3, {marginTop: '-20px'}, '-0.3');
  }

  window.onresize = function() {
    centerLogo();
  };
  centerLogo();
/* ----------------------------------------- Centraliza Logo no Menu */
